/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useGetReportFieldsQuery, useGetTemplateConfigurationQuery } from '../../Redux/services/report';
import TemplateAndCustomizableFieldsContainer from './TemplateAndCustomizableFieldsContainer';
import ReportTableAndFilterFieldsContainer from './ReportTableAndFilterFieldsContainer';
import './Report.css';

function Reports() {
  const { data: customizableFieldsData } = useGetReportFieldsQuery();
  const [customFields, setCustomFields] = useState({});
  const [customCheckedFields, setCustomCheckedFields] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: templateData } = useGetTemplateConfigurationQuery({ id: selectedTemplateId });
  const [filter, setFilter] = useState({});
  const [isMobileCompatible, setIsMobileCompatible] = useState(false);
  const [selectedFieldCount, setSelectedFieldCount] = useState(0);

  useEffect(() => {
    if (customizableFieldsData?.data) {
      setCustomFields(customizableFieldsData?.data);
    }
  }, [customizableFieldsData?.data]);

  function selectedReportFieldsCount(fieldArray) {
    return Object.keys(fieldArray).filter((field) => fieldArray[field]?.reportChecked).length;
  }

  const handleShowMobileCompatibilityIssue = () => {
    Swal.fire({
      text: 'To ensure mobile compatibility, you can select a maximum of 5 fields.',
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      heightAuto: false,
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
      },
      buttonsStyling: false,
    });
  };

  const handleShowClearingFieldsWarning = () => {
    Swal.fire({
      text: 'Toggling this on will clear your current selections and select 5 default fields. Do you want to proceed?',
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#161221',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
        cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let count = 0;
        setIsMobileCompatible(true);
        Object.keys(customFields).forEach((field) => {
          customFields[field].reportChecked = false;
          customFields[field].filterChecked = field === 'projectStartDate' || field === 'projectEndDate';
          if (count < process.env.REACT_APP_DEFAULT_FIELD_COUNT) {
            count += 1;
            customFields[field].reportChecked = true;
          }
        });
      } else {
        setIsMobileCompatible(false);
      }
    });
  };

  function updateCustomFieldsForReportAndFilterChecks() {
    if (templateData?.data) {
      const newCustomFields = { ...customFields };
      Object.keys(newCustomFields).forEach((fieldName) => {
        const found = templateData?.data?.find((template) => template.fieldName === fieldName);
        newCustomFields[fieldName] = {
          ...newCustomFields[fieldName],
          reportChecked: found?.report,
          filterChecked: found?.filter,
        };
      });
      setCustomFields(newCustomFields);
      setSelectedFieldCount(selectedReportFieldsCount(customFields));
    }
  }

  useEffect(() => {
    if (Object.keys(customFields).length !== 0 && templateData?.data) {
      updateCustomFieldsForReportAndFilterChecks();
    }
  }, [templateData?.data]);

  useEffect(() => {
    window.KTMenu.init();
  }, []);

  const taskDependantFields = Object.keys(customFields).filter((field) => (customFields[field]?.dependantTable === 'Tasks' || customFields[field]?.joinTable === 'Tasks') && field !== 'uniqueTaskId');

  const areTaskRelatedFieldsUnchecked = (updatedCustomFields) => (
    taskDependantFields.every((field) => !updatedCustomFields[field].reportChecked)
  );

  const handleCustomFieldChange = (fieldType, fieldName) => {
    const field = customFields[fieldName];
    if (!field) return;
    const updatedField = {
      ...field,
      [`${fieldType}Checked`]: !field[`${fieldType}Checked`],
    };

    const updatedCustomFields = {
      ...customFields,
      [fieldName]: updatedField,
    };

    if (fieldType === 'report' && taskDependantFields.includes(fieldName)) {
      const taskFieldsUnchecked = areTaskRelatedFieldsUnchecked(updatedCustomFields);

      updatedCustomFields.uniqueTaskId = {
        ...updatedCustomFields.uniqueTaskId,
        reportChecked: !taskFieldsUnchecked,
      };
    }

    if (fieldType === 'report' && isMobileCompatible && selectedReportFieldsCount(updatedCustomFields) > process.env.REACT_APP_DEFAULT_FIELD_COUNT) {
      handleShowMobileCompatibilityIssue();
      return;
    }

    setCustomFields(updatedCustomFields);
    setSelectedFieldCount(selectedReportFieldsCount(updatedCustomFields));
    setSelectedTemplateId('');
  };

  useEffect(() => {
    if (templateData?.data && customizableFieldsData?.data) {
      const customFieldsData = customizableFieldsData?.data;
      const fields = templateData?.data?.map((field) => field.fieldName);

      const filteredCustomFields = Object.keys(customFieldsData)
        ?.filter((key) => fields.includes(key))
        .map((key) => customFieldsData[key].label);

      const fieldConfig = templateData?.data?.map((field, index) => ({
        id: field.id,
        key: field.fieldName,
        filter: field.filter,
        report: field.report,
        label: filteredCustomFields[index],
      }));
      setCustomCheckedFields(fieldConfig);
    }

  }, [templateData?.data, customizableFieldsData?.data]);

  const handleMobileCompatibility = (e) => {
    if (e.target?.checked) {
      handleShowClearingFieldsWarning();
    } else {
      setIsMobileCompatible(e.target?.checked);
    }
    setSelectedFieldCount(customFields);
  };

  return (
    <>
      <TemplateAndCustomizableFieldsContainer
        handleCustomFieldChange={handleCustomFieldChange}
        customFields={customFields}
        customCheckedFields={customCheckedFields}
        setCustomCheckedFields={setCustomCheckedFields}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedTemplateId={selectedTemplateId}
        filtersData={filter}
        setCurrentPage={setCurrentPage}
        templateConfigurationData={templateData?.data}
        isMobileCompatible={isMobileCompatible}
        handleMobileCompatibility={handleMobileCompatibility}
        setIsMobileCompatible={setIsMobileCompatible}
        selectedFieldCount={selectedFieldCount}
        setSelectedFieldCount={setSelectedFieldCount}
      />
      <ReportTableAndFilterFieldsContainer
        customFields={customFields}
        customCheckedFields={customCheckedFields}
        selectedTemplateId={selectedTemplateId}
        setFilter={setFilter}
        filtersData={filter}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
}
export default Reports;
