/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  Modal, Form, Row, Col,
} from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import FlatPickr from 'react-flatpickr';
import moment from 'moment';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import { useUpdateProjectMutation, useGetProjectByIdQuery } from '../../Redux/services/project';
import { projectStatusConstants, userRoleConstants, userStatusConstants } from '../../utils/constant';
import { decryptRole } from '../../utils/cryptoUtils';

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function UpdateProjectModal({ show, onHide, projectId }) {
  const { data: masterData } = useGetMasterDataQuery();
  const { data: projectDetails } = useGetProjectByIdQuery(projectId);
  const [updateProject, {
    isLoading: updateLoading,
    isError,
  }] = useUpdateProjectMutation();

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (projectDetails && projectDetails.data) {
      const {
        name, status, owner, users, endDate, startDate,
      } = projectDetails.data.projectStats;

      setValue('projectName', name);
      if (status && status.id) {
        setValue('status', { value: status.id, label: status.name });
      } else {
        setValue('status', { value: '', label: 'No Status Available' });
      }
      setValue('startDate', moment(startDate).format('DD/MM/YYYY'));
      setValue('endDate', moment(endDate).format('DD/MM/YYYY'));
      setValue('owner', { value: owner.id, label: owner.fullName });
      setValue('users', users.map((user) => ({ value: user.id, label: user.fullName })));
    }
  }, [projectDetails, projectId, setValue, show]);

  const ownerOptions = masterData?.data.owners
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    ?.map((owner) => ({
      value: owner.id,
      label: `${owner.firstName} ${owner.lastName}`,
    })) || [];

  const userOptions = masterData?.data.users
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    .map((user) => ({ value: user.id, label: user.fullName })) || [];
  const statusOptions = masterData?.data.projectStatuses?.filter((status) => (status.id !== projectStatusConstants.COMPLETED)).map((status) => ({ value: status.id, label: status.name })) || [];

  async function onSubmit(formData) {
    try {
      const projectData = {
        name: formData.projectName,
        status: formData.status.value,
        owner: formData.owner.value,
        startDate: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        endDate: moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        users: formData.users.map((user) => user.value),
      };
      const endMomentDate = moment(projectData.endDate);
      const startMomentDate = moment(projectData.startDate);

      if (endMomentDate.isBefore(startMomentDate)) {
        return setError('endDate', { type: 'manual', message: 'End Date should be after Start Date' });
      }
      const result = await updateProject({ projectId, projectData });

      if (result.data.success) {
        window.toastr.success('Project Updated Successfully');
        onHide();
        reset();
      }
    } catch (error) {
      window.toastr.error('Failed to Update Project', error);
    }
    return null;
  }

  const handleClose = () => {
    onHide();
    reset();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Update Project</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Project Name <span className="text-danger">*</span></Form.Label>
                <input
                  {...register('projectName', {
                    required: { value: true, message: 'Required' },
                    validate: (value) => {
                      if (value.trim() === '') {
                        return 'Required';
                      }
                      if (value.trim().length < 3) {
                        return "Project name can't be less than 3 characters";
                      }
                      if (value.trim().length > 100) {
                        return "Project name can't be more then 100 characters";
                      }
                      return true;
                    },
                  })}
                  className="form-control"
                />
                {errors.projectName && <p className="text-danger">{errors.projectName.message}</p>}
              </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: 'required' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      options={statusOptions}
                      styles={customStyles}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      isDisabled={(projectDetails?.data?.projectStats?.status?.id === projectStatusConstants.INACTIVE) && moment().isBefore(moment(projectDetails?.data?.projectStats?.startDate, 'YYYY-MM-DD'))}
                    />
                  )}
                />
                {errors.status && <p className="text-danger">{errors.status.message}</p>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Start Date <span className="text-danger">*</span></Form.Label>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{ required: 'required' }}
                  render={({ field: { onChange, value } }) => (
                    <FlatPickr
                      value={value}
                      onChange={(date, currentdateString) => {
                        onChange(currentdateString);
                      }}
                      className="form-control"
                      disabled
                      options={{
                        dateFormat: 'd/m/Y',
                      }}
                    />
                  )}
                />
                {errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {errors.startDate.message}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>End Date <span className="text-danger">*</span></Form.Label>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{ required: 'required' }}
                  render={({ field: { onChange, value } }) => (
                    <FlatPickr
                      value={value}
                      onChange={(date, currentdateString) => {
                        onChange(currentdateString);
                      }}
                      className="form-control"
                      options={{
                        minDate: watch('startDate'),
                        dateFormat: 'd/m/Y',
                      }}
                    />
                  )}
                />
                {errors.endDate && (
                  <div className="invalid-feedback d-block">
                    {errors.endDate.message}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Owner <span className="text-danger">*</span></Form.Label>
                <Controller
                  name="owner"
                  control={control}
                  rules={{ required: 'required' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={ownerOptions}
                      isDisabled={roleMemo?.id === userRoleConstants.ADMIN}
                      styles={customStyles}
                    />
                  )}
                />
                {errors.owner && <p className="text-danger">{errors.owner.message}</p>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Users <span className="text-danger">*</span></Form.Label>
                <Controller
                  name="users"
                  control={control}
                  rules={{ required: 'At least one User is required' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={userOptions}
                      styles={customStyles}
                      isMulti
                    />
                  )}
                />
                {errors.users && <p className="text-danger">{errors.users.message}</p>}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-right mt-4 pt-5">
            <button className="btn btn-sm btn-secondary" type="submit" disabled={updateLoading}>
              {updateLoading ? 'Updating...' : 'Submit'}
            </button>
            <button type="button" className="btn btn-sm btn-light ms-3" onClick={handleClose}>
              Cancel
            </button>
          </div>
          {isError && <p className="text-danger mt-2">Failed to update project. Please try again.</p>}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateProjectModal;
