/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Row, Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import FlatPickr from 'react-flatpickr';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import { useCreateProjectMutation } from '../../Redux/services/project';
import { projectStatusConstants, userRoleConstants, userStatusConstants } from '../../utils/constant';
import { decryptRole } from '../../utils/cryptoUtils';

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function AddProjectModal({ show, onHide, onSuccess }) {
  const { data: masterData } = useGetMasterDataQuery();
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [decryptedRole, setDecryptedRole] = useState('');
  const {
    register, handleSubmit, setError, clearErrors, reset, formState: { errors },
  } = useForm();

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    setDecryptedRole(roleDecrypted);

    // eslint-disable-next-line
  }, []);

  // fetch logged in user from redux user slice
  const loggedInUser = useSelector((state) => state.user);

  useEffect(() => {
    if (show) {
      let loggedInOwner = null;
      const activeOwner = masterData?.data.owners.find(
        (owner) => owner.StatusId === userStatusConstants.ACTIVE && owner.id === loggedInUser.userId,
      );

      if (activeOwner) {
        loggedInOwner = decryptedRole?.id === userRoleConstants.ADMIN
          ? {
            value: activeOwner.id,
            label: `${activeOwner.firstName} ${activeOwner.lastName}`,
          }
          : null;
      }

      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setSelectedOwner(loggedInOwner);
      setSelectedUsers([]);
      setSelectedStatus(null);
      reset();
    }
    // eslint-disable-next-line
  }, [show, masterData, loggedInUser.userId, reset]);

  const statusOptions = masterData?.data.projectStatuses?.filter((status) => (status.id !== projectStatusConstants.COMPLETED)).map((status) => ({ value: status.id, label: status.name })) || [];
  const ownerOptions = masterData?.data.owners?.filter((owner) => (owner.StatusId === userStatusConstants.ACTIVE)).map((owner) => ({
    value: owner.id,
    label: `${owner.firstName} ${owner.lastName}`,
  })) || [];
  const userOptions = masterData?.data.users
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    .map((user) => ({ value: user.id, label: user.fullName })) || [];

  const validateFields = () => {
    let isValid = true;
    if (!selectedStartDate) {
      setError('startDate', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('startDate');
    }
    if (!selectedEndDate) {
      setError('endDate', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('endDate');
    }
    if (!selectedOwner) {
      setError('owner', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('owner');
    }

    if (selectedUsers.length === 0) {
      setError('users', { type: 'manual', message: 'At least one user is Required.' });
      isValid = false;
    } else {
      clearErrors('users');
    }

    return isValid;
  };

  const onSubmit = async (data) => {
    const isFormValid = validateFields();
    if (isFormValid) {
      const projectData = {
        name: data.projectName,
        startDate: moment(selectedStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        endDate: moment(selectedEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        status: selectedStatus.value,
        owner: selectedOwner.value,
        users: selectedUsers.map((user) => user.value),
      };
      try {
        await createProject(projectData).unwrap();
        window.toastr.success('Project Added Successfully');
        onHide();
        onSuccess();
        setSelectedStatus(null);
        reset();
      } catch (error) {
        window.toastr.error(`Project not added. Error: ${error.message}`);
      }
    }
  };

  const statusUpdateBasedOnStartDate = (startDate) => {
    const currentDate = moment();
    const startMomentDate = moment(startDate, 'DD/MM/YYYY');
    const isCurrentStartDateSame = (currentDate.isSame(startMomentDate, 'day')) && (currentDate.isSame(startMomentDate, 'month')) && (currentDate.isSame(startMomentDate, 'year'));
    if (currentDate.isBefore(startMomentDate)) {
      setSelectedStatus({ value: userStatusConstants.INACTIVE, label: 'Inactive' });
    } else if (currentDate.isAfter(startMomentDate) || isCurrentStartDateSame) {
      setSelectedStatus({ value: userStatusConstants.ACTIVE, label: 'Active' });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Add Project</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Row>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Project name <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  {...register('projectName', {
                    required: { value: true, message: 'Required' },
                    validate: (value) => {
                      if (value.trim() === '') {
                        return 'Required';
                      }
                      if (value.trim().length < 3) {
                        return "Project name can't be less than 3 characters";
                      }
                      if (value.trim().length > 100) {
                        return "Project name can't be more then 100 characters";
                      }
                      return true;
                    },
                  })}
                  onChange={() => clearErrors('projectName')}
                />
                {errors.projectName && (
                  <div className="invalid-feedback d-block">
                    {errors.projectName.message}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                <Select
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={(value) => setSelectedStatus(value)}
                  styles={customStyles}
                  isDisabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>Start Date <span className="text-danger">*</span></Form.Label>
                <FlatPickr
                  value={selectedStartDate}
                  className="form-control"
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                  onChange={(dates, currentdateString) => {
                    setSelectedStartDate(currentdateString);
                    statusUpdateBasedOnStartDate(currentdateString);
                    clearErrors('startDate');
                  }}
                />
                {errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {errors.startDate.message}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
              <Form.Group>
                <Form.Label>End Date <span className="text-danger">*</span></Form.Label>
                <FlatPickr
                  value={selectedEndDate}
                  className="form-control"
                  onChange={(dates, currentdateString) => {
                    setSelectedEndDate(currentdateString);
                    clearErrors('endDate');
                  }}
                  options={{
                    dateFormat: 'd/m/Y',
                    minDate: selectedStartDate || undefined,
                  }}
                  disabled={!selectedStartDate}
                />
                {errors.endDate && (
                  <div className="invalid-feedback d-block">
                    {errors.endDate.message}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Owner <span className="text-danger">*</span></Form.Label>
                <Select
                  value={selectedOwner}
                  onChange={(value) => {
                    setSelectedOwner(value);
                    clearErrors('owner');
                  }}
                  options={ownerOptions}
                  isDisabled={decryptedRole?.id === userRoleConstants.ADMIN}
                  styles={customStyles}
                />
                {errors.owner && (
                  <div className="invalid-feedback d-block">
                    {errors.owner.message}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Users <span className="text-danger">*</span></Form.Label>
                <Select
                  value={selectedUsers}
                  onChange={(value) => {
                    setSelectedUsers(value);
                    clearErrors('users');
                  }}
                  options={userOptions}
                  styles={customStyles}
                  isMulti
                />
                {errors.users && (
                  <div className="invalid-feedback d-block">
                    {errors.users.message}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-left pt-5 mt-5">
            <button className="btn btn-sm btn-secondary" type="submit">
              {isLoading ? 'Creating...' : 'Submit'}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-light ms-3"
              onClick={() => { onHide(); reset(); }}
            >
              Cancel
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddProjectModal;
