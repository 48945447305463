const regexPatterns = {
  USER_PASSWORD: /^(?=.*[A-Za-z])(?=.*\d).{6,20}$/,
  USER_PHONENUMBER: /^[9876]\d{9}$/,
  USER_EMAIL: /^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
  DURATION_GLOBAL: /^(24:00|(2[0-3]|1[0-9]|0?[0-9]):([0-5][0-9])$)|^(24h0m|24h00m|(2[0-3]|1[0-9]|0?[0-9])h([0-5][0-9])m$)|^(2[0-4]|1[0-9]|0?[0-9])h$|^([0-5]?[0-9])m$|^(24\.0|24\.00|((0|1?[0-9]|2[0-3])\.[0-9]{1,2}|23\.[0-9]{1,2}|23\.([0-9]{1})?[0-9]?)$)/,
};

const userRoleConstants = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
};

const userStatusConstants = {
  ACTIVE: 1,
  INACTIVE: 2,
};

const projectStatusConstants = {
  ACTIVE: 1,
  INACTIVE: 2,
  COMPLETED: 3,
};

const taskStatusConstants = {
  'TO DO': 1,
  'IN PROGRESS': 2,
  COMPLETED: 3,
};

const pollingIntervalOfTwoMinutes = 1000 * 60 * 2;

const clientStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
};

const roleHierarchyMapper = {
  'Super Admin': 'Admin',
  Admin: 'Manager',
  User: 'User',
};

const configurableModules = {
  TIMESHEET: 'Timesheet',
  REPORTS: 'Reports',
};

const reportCustomizableFieldsKeyMapper = {
  uniqueTaskId: 'uniqueTaskId',
  title: 'title',
  logHours: 'logHours',
  calculatedHours: 'calculatedHours',
  logDate: 'logDate',
  logDescription: 'logDescription',
  taskUsers: 'taskUsers',
  taskStatus: 'taskStatus',
  taskStartDate: 'taskStartDate',
  taskEndDate: 'taskEndDate',
  projectName: 'projectName',
  projectStatus: 'projectStatus',
  projectProgress: 'projectProgress',
  projectStartDate: 'projectStartDate',
  projectEndDate: 'projectEndDate',
  projectUsers: 'projectUsers',
  taskDescription: 'taskDescription',
  daysToGo: 'daysToGo',
  projectPercentComplete: 'projectPercentComplete',
  taskOverdue: 'taskOverdue',
  totalTaskCount: 'totalTaskCount',
  projectMembersCount: 'projectMembersCount',
  taskUserCount: 'taskUserCount',
  todoTaskCount: 'todoTaskCount',
  inProgressTaskCount: 'inProgressTaskCount',
  completedTaskCount: 'completedTaskCount',
};

const dropdownConstants = {
  DAYS_TO_GO_OPTIONS: [
    {
      label: '0-15',
      value: '0-15',
    },
    {
      label: '16-30',
      value: '16-30',
    },
    {
      label: '31-60',
      value: '31-60',
    },
    {
      label: '61-120',
      value: '61-120',
    },
    {
      label: '>120',
      value: '120-null',
    },
  ],
  PROJECT_PROGRESS_PERCENTAGE_OPTIONS: [
    {
      label: '0-25',
      value: '0-25',
    },
    {
      label: '26-50',
      value: '26-50',
    },
    {
      label: '51-75',
      value: '51-75',
    },
    {
      label: '76-100',
      value: '76-100',
    },
  ],

};

// eslint-disable-next-line
export { regexPatterns, userRoleConstants, userStatusConstants, projectStatusConstants, taskStatusConstants, pollingIntervalOfTwoMinutes, clientStatus, roleHierarchyMapper, configurableModules, reportCustomizableFieldsKeyMapper, dropdownConstants };
